import { isOffline, offlineRoutes } from '~/utils/helpers'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (isOffline() && !offlineRoutes.some((path) => path === to.path)) return navigateTo('/farske-oznamy')

  const parishConfig = useParishConfig()

  if (!Object.keys(parishConfig.value).length) {
    const { initParishConfig } = useUtils()
    await initParishConfig()
  }

  const isAllowed = !parishConfig.value.notAllowedPages?.includes(to.path)
  if (!isAllowed) {
    return navigateTo('/404')
  }
})
