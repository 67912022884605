import { DateTime } from 'luxon'

import type { ArticleContent, Seo, TileContent } from '~/utils/types'

const ToOfficeHoursTable = (officeHours: any): any => {
  const hasOfficeHours = Object.values(officeHours).some((val: any) => val.length > 0)
  const _hours: any = Object.entries(officeHours)
  const _finalObj: any = {}

  if (!hasOfficeHours) return {}

  for (const [key, value] of _hours) {
    const _array = []
    let dayOfWeek: undefined | string = undefined

    switch (key) {
      case 'monday':
        dayOfWeek = 'Pondelok'
        break
      case 'tuesday':
        dayOfWeek = 'Utorok'
        break
      case 'wednesday':
        dayOfWeek = 'Streda'
        break
      case 'thursday':
        dayOfWeek = 'Štvrtok'
        break
      case 'friday':
        dayOfWeek = 'Piatok'
        break
    }

    for (const data of value) {
      _array.push(Object.values(data).join(' – '))
    }

    if (dayOfWeek) _finalObj[dayOfWeek] = _array
  }

  // Find the length of the longest array
  const maxLength = Object.values(_finalObj).reduce((max: number, arr: any) => Math.max(max, arr.length), 0)

  // Loop through the object values and ensure they have the same length
  Object.values(_finalObj).forEach((arr: any) => {
    while (arr.length < maxLength) {
      const firstTwoChars = arr[0]?.substring(0, 2) ?? '00'
      if (firstTwoChars >= '00' && firstTwoChars <= '11') {
        arr.push('---') // Add a placeholder value to the end of the array
      } else if (firstTwoChars >= '12' && firstTwoChars <= '23') {
        arr.unshift('---') // Add a placeholder value to the start of the array
      } else {
        arr.push('---')
        break
      }
    }
  })

  return _finalObj
}

const ToMass = (data: any): TileContent => {
  return {
    date: data.date,
    time: data.time?.slice(0, 5) || data.timeRange || '',
    church: data.church?.name || '',
    city: data.church?.city?.name || '',
    description: data.description,
    badge: data.badge,
    isMass: !!data.isMass,
    isConfession: !!data.timeRange,
  }
}

const ToContentByCity = (data: TileContent[] | any[] = []) => {
  const contentByCity: any = {}

  data.forEach((content: TileContent) => {
    if (!contentByCity[content.city]) {
      contentByCity[content.city] = {}
    }
    if (!contentByCity[content.city][content.church]) {
      contentByCity[content.city][content.church] = []
    }

    contentByCity[content.city][content.church].push({
      time: content.time,
      description: content.description,
      badge: content.badge,
      isMass: content.isMass,
      isConfession: content.isConfession,
    })
  })

  if (!data.some((item) => item.isConfession)) {
    for (const city in contentByCity) {
      for (const church in contentByCity[city]) {
        contentByCity[city][church].sort((a: any, b: any) => {
          return a.time.localeCompare(b.time)
        })
      }
    }
  }

  return contentByCity
}

const ToAttachment = (data: any = [], mimeType: string): any[] => {
  const fixUrl = useFixUrl()

  return (
    data
      ?.filter((attachment: any) => attachment?.mime?.includes(mimeType))
      .map((data: any) => {
        data.url = fixUrl(data.url)
        return data
      }) || []
  )
}

export default () => {
  const route = useRoute()
  const {
    public: { host },
  } = useRuntimeConfig()
  const fixUrl = useFixUrl()

  const ToCategory = (data: any): any => {
    return {
      heading: data.heading,
      url: data.url,
      seo: ToSeoObject(data.seo),
    }
  }

  const ToSeoObject = (data: any): Seo | null => {
    if (!data) return null

    const og = data.metaSocial?.find((social: any) => social.socialNetwork === 'Facebook') || null
    const twitter = data.metaSocial?.find((social: any) => social.socialNetwork === 'Twitter') || null

    return {
      title: data.metaTitle,
      description: data.metaDescription,
      ogUrl: host + (route.path === '/' ? '' : route.path),
      ogType: 'website',
      ogTitle: og?.title || data.metaTitle,
      ogDescription: og?.description || data.metaDescription,
      ...((og?.image || data.metaImage) && {
        ogImageUrl: fixUrl(og?.image?.url) || fixUrl(data.metaImage?.url),
        ogImageType: og?.image?.mime || data.metaImage?.mime,
      }),
      ogLocale: 'sk_SK',
      twitterTitle: twitter?.title || og?.title || data.metaTitle,
      twitterDescription: twitter?.description || og?.description || data.metaDescription,
      ...((twitter?.image || og?.image || data.metaImage) && {
        twitterImage: fixUrl(twitter?.image?.url) || fixUrl(og?.image?.url) || fixUrl(data.metaImage?.url),
      }),
    }
  }

  const SetTimestamp = (data: any = undefined): any => {
    const currentTimestamp = DateTime.now().toISO()

    if (typeof data !== 'object' || !data) return currentTimestamp

    if (Array.isArray(data)) {
      return data.map((item) => {
        if (typeof item !== 'object' || !item) return item

        return {
          ...item,
          lastFetchedAt: currentTimestamp,
        }
      })
    }

    return {
      ...data,
      lastFetchedAt: currentTimestamp,
    }
  }

  const ToArticle = (data: any, isPodcastEpisode: boolean = false): ArticleContent | object => {
    if (!data) return {}

    return {
      heading: data.heading || data.title,
      url: data.url,
      text: data.text || data.description,
      publishedAt: data.publishedAt || data.date || null,
      updatedAt: data.updatedAt || null,
      image: data.image
        ? {
            url: data.image?.url || data.image,
            name: data.image?.name || data.podcast?.title,
            alternativeText: data.image?.alternativeText || data.title,
            mime: data.image?.mime,
          }
        : null,
      attachments:
        data.attachments?.length || (data.audioType && data.audioUrl)
          ? {
              data: data.attachments?.length
                ? data.attachments
                : [
                    {
                      mime: data.audioType,
                      url: data.audioUrl,
                    },
                  ],
            }
          : null,
      gallery: data.gallery || [],
      author: {
        fullName: data.author?.fullName || data.podcast?.author || null,
      },
      readTime: data.readTime?.text || null,
      seo: ToSeoObject(data.seo),
      isPodcastEpisode,
    }
  }

  return {
    ToArticle,
    ToContentByCity,
    ToMass,
    ToAttachment,
    ToCategory,
    ToOfficeHoursTable,
    ToSeoObject,
    SetTimestamp,
  }
}
