import type { DateTime } from 'luxon'

export const isOffline = () => {
  return import.meta.client && !window?.navigator?.onLine
}

export const offlineRoutes = ['/farske-oznamy']

export const formatToGraphqlDate = (date: DateTime) => {
  return date.toFormat('yyyy-MM-dd')
}

export const formatToSlovakDate = (date: DateTime) => {
  return date.toFormat('dd. MM. yyyy')
}

export const chunkArray = (array: any[], size: number) => {
  const chunkedArray = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }
  return chunkedArray
}
