
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as blog2ux47_451sSP2VzYTaM8q6tis_e_45h3pqY3JxKO4SdkXY4Meta } from "/home/ploi/www.fara-kezmarok.sk/pages/blog.vue?macro=true";
import { default as indexqMVUIC1E21vxSGIvt0HrLhbupcQPtFe1sqyoMEx1xvAMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/index.vue?macro=true";
import { default as nazivomNfdtdSqG77cOdGe_45O2aw4fMS3896uu6NkLkbOLrU9EMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/nazivo.vue?macro=true";
import { default as farske_45oznamyrBQFBNvfPrG5rHGOy41hk10eGUztcu7TnNW_KRl3E9gMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/farske-oznamy.vue?macro=true";
import { default as _91name_93rMpAxQiTkVKLdKROARc8LOVwd_TSjNAXiBcZSNl_jnwMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/kategoria/[name].vue?macro=true";
import { default as sluzba_45v_45kancelarii0vzSgaqa2taBJhcqNB3GvGMC1LhfYDR41hh3IcKq7ykMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/sluzba-v-kancelarii.vue?macro=true";
import { default as _91_46_46_46slug_93F0vl8JTnAdwMVbO6spnPA6qgr0ZiHmbD5gjuHGwjTwgMeta } from "/home/ploi/www.fara-kezmarok.sk/pages/[[category]]/[...slug].vue?macro=true";
export default [
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/blog.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/index.vue")
  },
  {
    name: "nazivo",
    path: "/nazivo",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/nazivo.vue")
  },
  {
    name: "farske-oznamy",
    path: "/farske-oznamy",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/farske-oznamy.vue")
  },
  {
    name: "kategoria-name",
    path: "/kategoria/:name()",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/kategoria/[name].vue")
  },
  {
    name: "sluzba-v-kancelarii",
    path: "/sluzba-v-kancelarii",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/sluzba-v-kancelarii.vue")
  },
  {
    name: "category-slug",
    path: "/:category?/:slug(.*)*",
    component: () => import("/home/ploi/www.fara-kezmarok.sk/pages/[[category]]/[...slug].vue")
  }
]