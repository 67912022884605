import revive_payload_client_s3yXlg9hemeHQyeQ_R0uNI_dnAa_72p3Dw04sZb2GsU from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LfsvkZnYSPf9E5s0slXT65lMBiO8lRA5z1bwLkq9GqY from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6fgOCcHWprgN4OD7bdiOc1lQAIr2z2poBOvwQ0CjWe4 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4bCBmIeC5rprRxec5KmrWxB2CyAbNXInytp2Jt4HCJg from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_O9vNWR0DtcolJJsAF2bbKIHARc16vrmo3FyyxTgsusA from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bho1iaxTOoZEIb0Sy2_Rv6WCB_AXYyFU3Hwe8ExwCYs from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oeijtDC7qHcNvLoCN71Nkpqhc_LX5TH0g2fgxBkcdJ0 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zlBseoz2FvOPST25WsxFPI9jvx9Zo7Va13cW7hhJxZI from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/ploi/www.fara-kezmarok.sk/.nuxt/components.plugin.mjs";
import prefetch_client_SmKGXmvnJz33TClti_j0tjOK4a24tvjcX305zSwuIQ0 from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.14.9_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6._mdtqzbpucnysbpmw3d572oydcm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/ploi/www.fara-kezmarok.sk/.nuxt/pwa-icons-plugin.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/home/ploi/www.fara-kezmarok.sk/plugins/sentry.client.ts";
import pwa_client_WA92IlKmLDs5EKfcEqO5AnoE_Vf8asMDU7Ol9d2GuuE from "/home/ploi/www.fara-kezmarok.sk/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.3_@types+node@20.14.9_jiti@2.4.2_sass@1.86.0_ter_tv4wcp46asaokrgsgkvj6kdi7e/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  revive_payload_client_s3yXlg9hemeHQyeQ_R0uNI_dnAa_72p3Dw04sZb2GsU,
  unhead_LfsvkZnYSPf9E5s0slXT65lMBiO8lRA5z1bwLkq9GqY,
  router_6fgOCcHWprgN4OD7bdiOc1lQAIr2z2poBOvwQ0CjWe4,
  _0_siteConfig_4bCBmIeC5rprRxec5KmrWxB2CyAbNXInytp2Jt4HCJg,
  payload_client_O9vNWR0DtcolJJsAF2bbKIHARc16vrmo3FyyxTgsusA,
  navigation_repaint_client_bho1iaxTOoZEIb0Sy2_Rv6WCB_AXYyFU3Hwe8ExwCYs,
  check_outdated_build_client_oeijtDC7qHcNvLoCN71Nkpqhc_LX5TH0g2fgxBkcdJ0,
  chunk_reload_client_zlBseoz2FvOPST25WsxFPI9jvx9Zo7Va13cW7hhJxZI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_SmKGXmvnJz33TClti_j0tjOK4a24tvjcX305zSwuIQ0,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  pwa_client_WA92IlKmLDs5EKfcEqO5AnoE_Vf8asMDU7Ol9d2GuuE
]