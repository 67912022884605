export enum ArticleCategory {
  blog = 'blog',
}

export interface Podcast {
  author: string
  title: string
  description: string
  image: string
  items: any[]
}

export interface ChurchInfo {
  time: string
  description?: string
  isMass: boolean
}

export interface ContentByCity {
  [city: string]: {
    [church: string]: ChurchInfo[]
  }
}

export type Days = {
  day: string
  date: string
  generalDate: string
  isOpen: boolean
}[]

export interface TileContent {
  date: string
  time: string
  church: string
  city: string
  description?: string
  badge?: string
  isMass: boolean
  isConfession: boolean
}

export interface StrapiContext {
  type: 'paragraph' | 'heading' | 'list' | 'text' | 'link' | 'list-item' | 'quote' | 'image'
  url?: string
  level?: number
  format?: 'unordered' | 'ordered'
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  code?: boolean
  text?: string
  image?: {
    url: string
    alternativeText: string | null
    width: number
    height: number
  }
  children: StrapiContext[]
}

export interface GalleryImage {
  documentId: string
  name: string
  url: string
  alternativeText?: string
}

export interface ArticleAttachment {
  mime: string
  url: string
}

export interface ArticleContent {
  heading: string
  url: string
  text?: string
  publishedAt: string | null
  image: GalleryImage | null
  attachments: ArticleAttachment[] | null
  gallery: GalleryImage[] | []
  readTime: string | null
  isPodcastEpisode: boolean
  author: {
    fullName: string | null
  }
  seo: Seo | null
}

export interface ParishConfig {
  name: string
  description: string
  notAllowedPages: string[]
  sitemap: string
  lang?: string
  colors: {
    primary: string
    secondary: {
      light: string
      DEFAULT: string
      dark: string
    }
    crossDefault: string
  }
  analytics: {
    umamiWebsiteId: string
    googleAnalyticsId: string
  }
  sentry: {
    tracePropagationTargets: string[]
  }
  manifest: {
    shortName: string
  }
}

export interface Seo {
  title: string
  description: string
  ogUrl?: string
  ogType: 'website' | 'article'
  ogTitle: string
  ogDescription: string
  ogImageUrl?: string
  ogImageType?: 'image/jpeg' | 'image/gif' | 'image/png'
  ogLocale: string
  twitterTitle: string
  twitterDescription: string
  twitterImage?: string
}
